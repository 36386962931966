import urlLibrary from "./urlLibrary";
import { getData } from "./fetchService"
import jwtDecode from "jwt-decode";

export default class AuthService {
    static instance: any = null;
    loggedIn: boolean = false;
    refreshTimer: any;
    user: any = null;
    tokens: any = null;
    deviceId: string | null = null;

    static createInstance() {
        var object = new AuthService();
        return object;
    }

    static getInstance() {
        if (!AuthService.instance) {
            AuthService.instance = AuthService.createInstance();
        }
        return AuthService.instance;
    }

    public async refreshTokens() {
        try {
            const tokens: any = await getData(urlLibrary.auth.refreshTokens, { refreshToken: this.tokens.refresh_token, appType: "dataIntel" })
            this.tokens = {
                access_token: tokens.access_token,
                refresh_token: tokens.refresh_token,
                id_token: tokens.id_token
            }
            this.user = jwtDecode(tokens.access_token);
            localStorage.setItem("user", JSON.stringify(this.user));
            return this.user;
        } catch (err: any) {
            console.log(err);
            localStorage.removeItem("user");
            return null;
        };
    }

    public getUser() {
        if(this.user) return this.user;
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            return JSON.parse(storedUser)
        }
        return null;
    }
    
    public getTokens() {
        if(this.tokens) return this.tokens;
        const storedTokens = localStorage.getItem('tokens');
        if (storedTokens) {
            return JSON.parse(storedTokens);
        }
        return null;
    }

    public async checkLogin() {
        const cachedTokens = localStorage.getItem("tokens") || "{}";
        const tokens = JSON.parse(cachedTokens);
        if (tokens?.refresh_token) {
            this.tokens = tokens;
            const parsedUser = localStorage.getItem("user") || "{}";
            this.user = JSON.parse(parsedUser);
        } else {
            localStorage.removeItem("user");
            localStorage.removeItem("tokens");
            return false;
        }
        const tokensRefreshed = await this.refreshTokens();
        return tokensRefreshed;
    }

    public async login(otp: string, phone: string, captcha: string) {
        try {
            const tokens: any = await getData(urlLibrary.auth.verifyOTP, { otp, phone, captcha, deviceId: this.getDeviceId() });
            const token = tokens?.access_token;
            this.tokens = {
                access_token: tokens.access_token,
                refresh_token: tokens.refresh_token,
                id_token: tokens.id_token
            }
            this.user = jwtDecode(token);
            localStorage.setItem("user", JSON.stringify(this.user));
            localStorage.setItem("tokens", JSON.stringify(this.tokens));
            this.loggedIn = true;
            return tokens;
        } catch(error: any) {
            return {status: 400, message: error.message};
        }
    }

    public logout() {
        // window.clearInterval(this.refreshTimer);
        this.loggedIn = false;
        this.user = null;
        this.tokens = null;
        localStorage.removeItem("user");
        localStorage.removeItem("tokens");
    }

    public async getOtp(phone: string, captcha: string) {
        const deviceId = this.getDeviceId();
        try {
            const otp: any = await getData(urlLibrary.auth.getOTP, {
                phone,
                captcha,
                deviceId,
                appType: "dataIntel"
            });
            return otp;
        } catch(error: any) {
            return { status: 400, message: error.message };
        }
    }

    private randomString(length: number) {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    private randomUniqueString() {
        return this.randomString(4) + Date.now().toString(16);
    }

    private getDeviceId() {
        if(this.deviceId) return this.deviceId;
        const storedDeviceId = localStorage.getItem("deviceId");
        if(storedDeviceId) {
            this.deviceId = storedDeviceId;
            return storedDeviceId;
        }
        const newDeviceId = this.randomUniqueString();
        localStorage.setItem("deviceId", newDeviceId);
        this.deviceId = newDeviceId;
        return newDeviceId;
    }
    
    public async registerDevice() {
        try {
            const deviceRegister: any = await getData(urlLibrary.auth.registerDevice, {
                deviceId: this.getDeviceId(),
                appType: "dataIntel",
                language: "en",
                pushToken: "test push token",
                platform: "web",
                appVersion: "1.0",
                deviceName: "web",
                osVersion: "web"
            });
            return deviceRegister;
        } catch(error: any) {
            return { status: 400, message: error.message };
        }
    }
}