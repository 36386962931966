import React, { useEffect, useRef, useState } from "react";
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager";
import { HeatmapLayer } from 'react-google-maps/lib/components/visualization/HeatmapLayer';

import LayersClearIcon from '@mui/icons-material/LayersClear';
import CustomImageWindow from "./CustomImageWindow";

// const gradient = [
//     "rgba(181, 209, 229, 0)",
//     "rgb(170, 202, 225)",
//     "rgb(159, 195, 221)",
//     "rgb(149, 187, 217)",
//     "rgb(138, 180, 213)",
//     "rgb(128, 173, 210)",
//     "rgb(117, 166, 206)",
//     "rgb(106, 159, 202)",
//     "rgb(95, 151, 198)",
//     "rgb(84, 144, 194)",
//     "rgb(73, 137, 191)",
//     "rgb(60, 130, 187)",
//     "rgb(46, 123, 183)",
//     "rgb(29, 116, 179)",
// ];

const gradient = [
    "rgba(0, 255, 255, 0)",
    "rgba(0, 255, 255, 1)",
    "rgba(0, 191, 255, 1)",
    "rgba(0, 127, 255, 1)",
    "rgba(0, 63, 255, 1)",
    "rgba(0, 0, 255, 1)",
    "rgba(0, 0, 223, 1)",
    "rgba(0, 0, 191, 1)",
    "rgba(0, 0, 159, 1)",
    "rgba(0, 0, 127, 1)",
    "rgba(63, 0, 91, 1)",
    "rgba(127, 0, 63, 1)",
    "rgba(191, 0, 31, 1)",
    "rgba(255, 0, 0, 1)",
];



const SearchMap = withGoogleMap((props) => {
    const {updateMap, clearMap, bounds, data, selectedTab, nearby} = props;
    const [shape, setShape] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedId, setSelectedId] = useState("");

    const getBoundingBox = (bounds) => {
        if (!bounds) return null;
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        if(sw.lat() === ne.lat() || sw.lng() === ne.lng()) {
            setShape(null);
            alert("Invalid box selected");
            return null;
        }
        const boundingBox = `${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()}`;
        return boundingBox;
    }

    const getPolygonBoundingBox = (polygon) => {
        let boundingBox = "[";
        let firstCoord = "";
        let separator = "";
        polygon.getPaths().forEach((path) => {
            path.forEach((latlng, index) => {
                const thisCoord = `[${latlng.lng()},${latlng.lat()}]`;
                if (index === 0) firstCoord = thisCoord;
                boundingBox += (separator + thisCoord);
                separator = ",";
            })
        });
        boundingBox += (separator + firstCoord + "]");
        return boundingBox;
    }

    const drawComplete = (newObject, shapeType) => {
        if (shape) shape.setMap(null);
        let boundingBox = "";
        if (shapeType === "rectangle") {
            boundingBox = getBoundingBox(newObject.getBounds());
        } else if (shapeType === "polygon") {
            boundingBox = getPolygonBoundingBox(newObject);
        }
        if (!boundingBox) return;
        setShape(newObject);
        if(selectedTab === 0) updateMap(boundingBox);
    }

    const clearShape = () => {
        if(shape) shape.setMap(null);
        setShape(null);
        if (selectedTab === 0) updateMap(getBoundingBox(map.current.getBounds()));
    }

    const onBoundsChanged = () => {
        if (selectedTab === 1) return;
        if(shape) return;
        clearMap(false);
        const newBounds = getBoundingBox(map.current.getBounds());
        if(!newBounds || bounds === newBounds) return;
        updateMap(newBounds);
    }

    useEffect(() => {
        if (map && map.current) map.current.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(new window.google.maps.LatLng(props.center));
    }, [props.center.lat, props.center.lng]);

    useEffect(() => {
        if(selectedTab === 1) clearShape();
    }, [selectedTab]);

    const map = useRef();

    const toggleInfoWindow = (markerId = '') => {
        if(markerId) {
            setShowDetails(true);
            setSelectedId(markerId);
        } else {
            setShowDetails(false);
            setSelectedId("");
        }
    }
    
    return (
        <div className="maps-immediate-container" style={{position: 'absolute', top: '0px', width: '100%'}}>
            <GoogleMap
                zoom={props.zoom}
                mapTypeId='terrain'
                defaultCenter={props.center}
                options={{
                    gestureHandling: 'greedy',
                    mapTypeControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    styles: [
                        {
                            featureType: "administrative",
                            elementType: "labels.text.fill",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "administrative",
                            elementType: "labels.text.stroke",
                            stylers: [{ color: "#777" }],
                        },
                        {
                            featureType: "poi.business",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "poi.park",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "poi.place_of_worship",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "poi.school",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "poi.sports_complex",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "transit",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "road",
                            elementType: "labels.text.fill",
                            stylers: [{ visibility: "off" }],
                        },
                        {
                            featureType: "road",
                            elementType: "labels.text.stroke",
                            stylers: [{ color: "#777", }],
                        },
                        {
                            featureType: "road.local",
                            elementType: "labels",
                            stylers: [{ visibility: "off" }],
                        },
                    ]
                }}
                onBoundsChanged={onBoundsChanged}
                ref={map}
            >
                { data.length > 400 &&
                    <>
                        <HeatmapLayer data={data} />
                        { nearby &&
                            <HeatmapLayer data={nearby} options={{gradient}} />
                        }
                    </>
                }
                { data.length <= 400 &&
                    <MarkerClusterer averageCenter enableRetinaIcons>
                        { data.map((marker, index) => (
                            <Marker 
                                key={index} 
                                position={marker}
                                icon={{
                                    url: (require('../assets/blueMarker.svg')).default,
                                    scale: 7
                                }}
                                onClick={() => toggleInfoWindow(marker._id)}
                            >
                                { (showDetails && selectedId === marker._id) &&
                                    <InfoWindow onCloseClick={toggleInfoWindow}>
                                        <CustomImageWindow marker={marker} />
                                    </InfoWindow>
                                }
                            </Marker>
                        ))}
                        { nearby && nearby.map((marker, index) => (
                            <Marker
                                key={"n-" + index}
                                position={marker}
                                icon={{
                                    url: (require('../assets/redMarker.svg')).default,
                                    scale: 7
                                }}
                                onClick={() => toggleInfoWindow(marker._id)}
                            >
                                {(showDetails && selectedId === marker._id) &&
                                    <InfoWindow onCloseClick={toggleInfoWindow}>
                                        <CustomImageWindow marker={marker} />
                                    </InfoWindow>
                                }
                            </Marker>
                        ))}
                    </MarkerClusterer>
                }
                { selectedTab === 0 &&
                    <React.Fragment>
                        <DrawingManager
                            defaultOptions={{
                                drawingControl: true,
                                drawingControlOptions: {
                                    position: window.google.maps.ControlPosition.TOP_CENTER,
                                    drawingModes: [
                                        // window.google.maps.drawing.OverlayType.POLYGON,
                                        window.google.maps.drawing.OverlayType.RECTANGLE
                                    ]
                                },
                                rectangleOptions: {
                                    fillColor: `rgba(3, 221, 121,0)`,
                                    fillOpacity: 0.5,
                                    strokeWeight: 5,
                                    clickable: false,
                                    editable: true,
                                    zIndex: 1,
                                },
                                // polygonOptions: {
                                //     fillColor: `rgba(3, 221, 121,0)`,
                                //     fillOpacity: 0.5,
                                //     strokeWeight: 5,
                                //     clickable: false,
                                //     editable: true,
                                //     zIndex: 1,
                                // }
                            }}
                            onRectangleComplete={(shape) => drawComplete(shape, "rectangle")}
                            onPolygonComplete={(shape) => drawComplete(shape, "polygon")}
                        />
                        <div style={{ textAlign: 'center', position: 'absolute', top: 5, backgroundColor: 'white', boxShadow: '0px 1px 4px #ccc', marginLeft: '24px', height: '27px', width: '20px', padding: '0px 2px', cursor: 'pointer', display: 'inline-block' }} onClick={clearShape}>
                            <LayersClearIcon fontSize="16px" style={{ marginTop: '3px' }} />
                        </div>
                    </React.Fragment>
                }
            </GoogleMap>
        </div>
    )
});

export default SearchMap;