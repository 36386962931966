import { authHeader, uploadHeader, getToken } from "./auth-headers";
import urlLibrary, { RequestParams } from "./urlLibrary";
import AuthService from "./auth.service";
import axios, { AxiosError, Method } from 'axios';

export const methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
}

interface request {
    url?: string
    method: Method,
    headers: { [key: string]: string }
    body?: any,
    data?: any
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(async (response: any) => {
    // const text = await response.text();
    const { data } = response;
    if ('data' in data) {
        return data.data
    }
    return data;
}, async (error: AxiosError) => {
    const originalRequest: any = { ...error.config };
    if (error.response?.status === 401) {
        const authService = AuthService.getInstance();
        authService.logout();
    }
    if (error.response?.status === 403 && !error.config.url!.includes(urlLibrary.auth.refreshTokens.url) && !originalRequest._retry) {
        const authService = AuthService.getInstance();
        const tokenRefresh = await authService.refreshTokens();
        if (tokenRefresh) {
            originalRequest._retry = true;
            const token = getToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axiosInstance(originalRequest);
        }
    }
    const errorResponse: any = error.response;
    let message = error.message;
    if ("data" in errorResponse && errorResponse.data && "message" in errorResponse.data && errorResponse.data.message) {
        message = errorResponse.data.message;
    }
    return Promise.reject(message || 'Unable to connect to server');
});

async function handleResponse(response: Response) {
    if (response.status === 401 || response.status === 403) {
        const authService = AuthService.getInstance();
        authService.logout();
    }
    if (response.ok) {
        return response.text().then((text: string) => {
            // if(response.status === 401) {
            //     //logout as server rejected user
            //     logout();
            //     window.location.reload();
            // }
            const data = text && JSON.parse(text);
            if ('data' in data) {
                return data.data
            }
            return data;
        });
    }
    try {
        const responseText = await response.text();
        const json = responseText && JSON.parse(responseText);
        if (json) return Promise.reject(new Error(json.message));
    } catch (err) {
        console.log(err);
    }
    return Promise.reject(response.statusText || "Unable to connect to server");
}

export function getData(requestParams: RequestParams, data: any = null) {
    let finalRequestParams: RequestParams = { ...requestParams };
    const requestOptions: request = {
        method: requestParams.method as Method,
        headers: { 'Content-Type': 'application/json' },
    }
    if (data) {
        requestOptions.body = JSON.stringify(data)
    }
    const url = process.env.REACT_APP_BASE_AUTH_URL + finalRequestParams.url;
    return fetch(url, requestOptions).then(handleResponse);
}

export async function adminRequest(requestParams: RequestParams, data: { [key: string]: any } | null = null, headers: { [key: string]: string } | null = null, type: string = "json") {
    let finalRequestParams: RequestParams = { ...requestParams };
    const baseHeaders = type === "json" ? authHeader(requestParams) : uploadHeader();
    const requestHeaders = { ...baseHeaders, ...headers };
    const requestOptions: request = {
        url: finalRequestParams.url.substring(0, 4) === 'http' ? finalRequestParams.url : (process.env.REACT_APP_BASE_URL + finalRequestParams.url),
        method: requestParams.method as Method,
        headers: requestHeaders
    }
    if (data) {
        requestOptions.data = data;
    }
    return axiosInstance(requestOptions)
}