import React, {Component} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import getImage from '../util/getImage';

class ResultGrid extends Component {

    render() {
        const {samples} = this.props;
        return (
            <div className="poi-grid-root" >
                <ImageList rowHeight={340} cols={2} className="poi-grid-list" >
                    {samples.map((item, index) => (
                        <ImageListItem key={index} cols={1} rows={1}>
                            <img src={process.env.REACT_APP_POIIMG_BASE_URL + getImage(item)} alt={item.name} loading="lazy" style={{ height: '100%', width: '100%' }} />
                            <ImageListItemBar
                                title={
                                    <React.Fragment>
                                        <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                            {item.name}
                                        </div>
                                        <div style={{ lineHeight: '0.8em', textAlign: 'left' }}>
                                            <LocationOnIcon style={{ fontSize: '0.8em', color: 'red' }} /> <span style={{ fontSize: '0.8em', textOverflow: 'ellipsis' }}>{item.address?.locality}, {item.address?.city}</span>
                                        </div>
                                    </React.Fragment>
                                }
                                position="bottom"
                                actionPosition="left"
                            // className="poi-grid-title"
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
        )
    }
}

export default ResultGrid;