import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import getImage from '../util/getImage';

const CustomImageWindow = (props) => {
    return(
        <ImageList rowHeight={340} cols={1} className="poi-grid-list" >
            <ImageListItem key={"1"} cols={1} rows={1}>
                <img src={process.env.REACT_APP_POIIMG_BASE_URL + getImage(props.marker.data)} alt={props.marker.data.name} loading="lazy" style={{ height: '100%', width: '100%' }} />
                <ImageListItemBar
                    title={
                        <React.Fragment>
                            <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                {props.marker.data.name}
                            </div>
                            <div style={{ lineHeight: '0.8em', textAlign: 'left' }}>
                                <LocationOnIcon style={{ fontSize: '0.8em', color: 'red' }} /> <span style={{ fontSize: '0.8em', textOverflow: 'ellipsis' }}>{props.marker.data.address?.locality}, {props.marker.data.address?.city}</span>
                            </div>
                        </React.Fragment>
                    }
                    position="bottom"
                    actionPosition="left"
                // className="poi-grid-title"
                />
            </ImageListItem>
        </ImageList>
    )
}

export default CustomImageWindow;