import React, { Component } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TuneIcon from '@mui/icons-material/Tune';
import HelpIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import SquareTwoToneIcon from '@mui/icons-material/SquareTwoTone';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import CircleIcon from '@mui/icons-material/Circle';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import ReCAPTCHA from "react-google-recaptcha";
import TextTransition, {presets} from "react-text-transition";

import SearchMap from "../components/SearchMap";
import { downloadResults, fetchUserCategories, fetchCounts, fetchStats, fetchUserList, initPayment, verifyPurchase, fetchCategories } from "../util/api.service";
import ScriptService from "../util/loadScript";
import ResultGrid from "../components/ResultGrid";
import { getRupees } from "../util/rupees";
import reportEvent from "../util/google-analytics";

const radiusOptions = [
    { value: 1, label: '1 km' },
    { value: 2, label: '2 km' },
    { value: 5, label: '5 km' },
    { value: 10, label: '10 km' },
    { value: 20, label: '20 km' },
    { value: 50, label: '50 km' },
];
const relatedRadiusOptions = [
    { value: 100, label: '100m' },
    { value: 500, label: '500m' },
    { value: 1000, label: '1km' },
    { value: 5000, label: '5km' },
];
const downloadText = [
    "Your download is being prepared...",
    "Once the data is ready, you will get a file save popup...",
    "Check 'Previous Searches' tab to download later...",
    "We'll also send you a message when done...",
    "Collecting data points...",
    "Removing false positives...",
    "Pulling images...",
    "Why is this taking so long...?",
];

const searchText = [
    "Filtering points of interest by location...",
    "Applying search parameters...",
    "Collecting details...",
    "Why is this taking so long...?",
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const savedSearches = {};
const categoriesLookup = {};
let downloadInterval = null;

class SearchPage extends Component {
    
    state = {
        locationQuery: "",
        fetchingPOIs: true,
        fetchingCategories: true,
        fetchingCounts: false,
        countsFetched: false,
        markers: [],
        nearbyMarkers: [],
        samples: [],
        resultCount: null,
        categories: [],
        latitude: parseFloat(process.env.REACT_APP_MAP_LAT),
        longitude: parseFloat(process.env.REACT_APP_MAP_LNG),
        zoom: 15,
        category: [],
        relatedCategory: [],
        relatedRadius: relatedRadiusOptions[2],
        showAdvancedSearchTools: false,
        phonePresent: false,
        emailPresent: false,
        displayCaptcha: false,
        mapsLoaded: false,
        // boundingBox: "28.561634655085516,77.09998189126391,28.6663681303826,77.31764851724047"
        boundingBox: "",
        selectedTab: 0,
        previousResults: null,
        selectedResult: null,
        orderId: null,
        rzpOrderId: null,
        price: null,
        waitingForDownload: false,
        downloadTextIndex: 0
    }

    mapQuery = null;

    componentDidMount() {
        const categories = [];
        fetchUserCategories()
        .then((categoriesResults) => {
            // for (let i = 0; i < categoriesResults.userCategories.length; i++) {
            for (let i = 0; i < categoriesResults.length; i++) {
                let categoryObj = {};
                // categoryObj.value = categoriesResults.userCategories[i].id;
                // categoryObj.label = categoriesResults.userCategories[i].name;
                categoryObj.value = categoriesResults[i].category;
                categoryObj.label = categoriesResults[i].displayCategory;
                categories.push(categoryObj);
            }
            this.setState({categories, fetchingCategories: false});
        });
        
        const scriptService = ScriptService.getInstance();
        scriptService.loadScript(`https://maps.googleapis.com/maps/api/js?key=` + process.env.REACT_APP_GOOGLEMAP_APIKEY + `&libraries=places,visualization,drawing`,
            () => {
                this.setState({mapsLoaded: true});
                this.setupAutocomplete();
                this.fetchStats(true);
            });
        scriptService.loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {});
    }

    getFetchParams = (initialLoad) => {
        const { latitude, longitude, category, reCaptcha, boundingBox, relatedCategory, relatedRadius } = this.state;
        if (!(latitude && longitude) || (!initialLoad && !boundingBox)) {
            // TODO: Show better error
            alert("Please fill in required fields");
            return;
        }
        let categoryList = "";
        let separator = "";
        for(let i=0; i<category.length; i++) {
            categoryList += (separator + category[i].value);
            separator = ",";
        }
        
        let relatedCategoryList = "";
        separator = "";
        for(let i=0; i<relatedCategory.length; i++) {
            relatedCategoryList += (separator + relatedCategory[i].value);
            separator = ",";
        }
        
        return boundingBox ?
            {
                boundingBox,
                categoryId: categoryList,
                reCaptcha: reCaptcha,
                relatedCategories: relatedCategoryList,
                relatedRadius
            }
            : {
                lat: latitude,
                lng: longitude,
                categoryId: categoryList,
                reCaptcha: reCaptcha,
                relatedCategories: relatedCategoryList,
                relatedRadius
            }
    }
    
    fetchStats = (initialLoad = false) => {
        const fetchParams = this.getFetchParams(initialLoad);
        if(!fetchParams.boundingBox) return;
        const thisComponent = this;
        const markers = [];
        const nearbyMarkers = [];
        this.setState({ fetchingPOIs: true, countsFetched: false });
        let resultCount = 0;
        fetchStats(fetchParams.boundingBox, fetchParams.categoryId, fetchParams.relatedCategories, fetchParams.relatedRadius)
            .then((data) => {
                for (const poiData of data.pois) {
                    for(let i=0;i<poiData.count; i++) {
                        const poi = new window.google.maps.LatLng({ lat: poiData.blat, lng: poiData.blng });
                        markers.push(poi);
                    }
                    resultCount += poiData.count;
                }
                for (const nearbyData of data.nearby) {
                    for(let i=0;i<nearbyData.count; i++) {
                        const poi = new window.google.maps.LatLng({ lat: nearbyData.blat, lng: nearbyData.blng });
                        nearbyMarkers.push(poi);
                    }
                    resultCount += nearbyData.count;
                }
                thisComponent.setState({ fetchingPOIs: false, resultCount, markers, nearbyMarkers });
            });
    }

    getZoom = (bounds) => {
        const GLOBE_WIDTH = 256;
        const west = bounds.getSouthWest().lng();
        const east = bounds.getNorthEast().lng();
        var angle = east - west;
        if(angle < 0) angle += 360;
        return Math.round(Math.log(400 * 360 / angle / GLOBE_WIDTH) / Math.LN2);
    }

    setMarkers = (markerData, nearbyData = []) => {
        if(!markerData || !Array.isArray(markerData)) {
            this.setState({ markers: [], samples: markerData, fetchingPOIs: false, resultCount: markerData.length, countsFetched: true, latitude: parseFloat(process.env.REACT_APP_MAP_LAT), longitude: parseFloat(process.env.REACT_APP_MAP_LNG), zoom: 16 });
        }
        const markers = [];
        const nearbyMarkers = [];
        var bounds = new window.google.maps.LatLngBounds();
        for (const poiData of markerData) {
            const poi = new window.google.maps.LatLng({ lat: poiData.l.lat, lng: poiData.l.lng });
            poi._id = poiData._id;
            poi.data = poiData;
            bounds.extend(poi);
            markers.push(poi);
        }
        for(const nearby of nearbyData) {
            const nearbyP = new window.google.maps.LatLng({lat: nearbyData.l.lat, lng: nearbyData.l.lng});
            nearbyP._id = nearbyData._id;
            nearbyP.data = nearby;
            bounds.extend(nearbyP);
            nearbyMarkers.push(nearbyP)
        }
        const center = bounds.getCenter();
        const zoom = this.getZoom(bounds);
        this.setState({ markers, nearbyMarkers ,samples: markerData, fetchingPOIs: false, resultCount: markerData.length, countsFetched: true, latitude: center.lat(), longitude: center.lng(), zoom });
    }

    fetchCounts = () => {
        // const {user} = this.props;
        // if(!user) {
        //     this.props.toggleLoginDialog();
        //     return;
        // }
        const thisComponent = this;
        this.setState({fetchingCounts: true});
        const fetchParams = this.getFetchParams();
        fetchCounts(fetchParams.boundingBox, fetchParams.categoryId, fetchParams.relatedCategories, fetchParams.relatedRadius)
            .then((data) => {
                const totalResults = data.count + (data.nearbyCount || 0);
                thisComponent.setState({ fetchingCounts: false, resultCount: totalResults, orderId: data.orderId, price: data.price, samples: data.pois, countsFetched: true });
                reportEvent('search', 'click', 'Counts query run to see sample results');
            });
    }

    setupAutocomplete = () => {
        let input = document.getElementById('autocomplete-input');
        let autocomplete = new window.google.maps.places.Autocomplete(input);
        const thisComponent = this;
        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            // alert("lat-"+place.geometry.location.lat()+",lng-"+place.geometry.location.lng());
            if (!place.geometry) {
                return;
            }
            thisComponent.setState({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                locationQuery: place.name
            });
        });
    }

    setLocationQuery = (query) => {
        this.setState({locationQuery: query});
    }

    showAdvancedTools = () => {
        reportEvent('search', 'click', `Advanced search tools ${this.state.showAdvancedSearchTools ? 'hidden' : 'shown' }`);
        this.setState({showAdvancedSearchTools: !this.state.showAdvancedSearchTools});
    }

    hideCaptcha = () => {
        this.setState({displayCaptcha: false});
    }

    checkCaptcha = () => {
        reportEvent('search', 'click', 'Search button clicked');
        if(!this.state.reCaptcha) {
            this.setState({displayCaptcha: true});
            return;
        }
        this.fetchStats();
    }

    setCaptcha = (value) => {
        const thisComponent = this;
        if(value) {
            this.setState({reCaptcha: value}, () => {
                this.captchaRef?.reset();
                this.fetchStats();
                thisComponent.setState({displayCaptcha: false});
            });
        } else {
            this.captchaRef?.reset();
        }
    }

    updateMap = (shape) => {
        const newState = {
            boundingBox: shape,
            countsFetched: false,
            resultCount: null,
            fetchingPOIs: true
        };
        const thisComponent = this;
        this.setState({...newState}, () => {
            if(thisComponent.mapQuery !== null) window.clearTimeout(thisComponent.mapQuery);
            thisComponent.mapQuery = window.setTimeout(() => {
                thisComponent.fetchStats();
            }, 500);
        });
    }

    clearMap = (recenter = true) => {
        if(recenter) {
            this.setState({
                markers: [],
                pois: [],
                samples: [],
                countsFetched: false,
                fetchingPreviousResults: false,
                latitude: parseFloat(process.env.REACT_APP_MAP_LAT),
                longitude: parseFloat(process.env.REACT_APP_MAP_LNG),
                zoom: 15
            });
        } else {
            this.setState({
                markers: [],
                pois: [],
                samples: []
            });
        }
    }

    downloadPreviousResults = () => {
        fetchUserList().then(results => {
            results.sort((a, b) => {
                return (Date.parse(b.lastModified) - Date.parse(a.lastModified));
            });
            this.setState({ previousResults: results, fetchingPreviousResults: false, markers: [] });
        });
    }

    changeTab = (event, selectedTab) => {
        if (selectedTab === 1 && !this.props.user) {
            return;
        }
        this.setState({selectedTab: selectedTab, fetchingPreviousResults: true});
        if(selectedTab === 1) {
            if(this.state.previousResults === null) {
                this.downloadPreviousResults();
            } else {
                this.setState({ fetchingPreviousResults: false, markers: [] });
            }
            reportEvent('search', 'tab', 'Tab changed to previous results');
        }
        if(selectedTab === 0) {
            this.clearMap();
            reportEvent('search', 'tab', 'Tab changed to search');
        }
    }

    togglePanel = (resultIndex, objectKey) => {
        const {selectedResult} = this.state;
        if(selectedResult === resultIndex) {
            this.setState({selectedResult: null});
            this.clearMap();
        } else {
            this.setState({selectedResult: resultIndex});
            if(objectKey in savedSearches) {
                this.setMarkers(savedSearches[objectKey]);
            }
        }
    }

    // getSingleLineAddress = (address) => {
    //     const ret = "";
    //     const separator = "";
    //     if(address.city) {
    //         ret += (separator + address.city);
    //         separator = ", ";
    //     }
    //     if(address.state) {
    //         ret += (separator + address.state);
    //         separator = ", ";
    //     }
    //     if(address.postalCode) {
    //         ret += (separator + address.postalCode);
    //     }
    // }

    offerDownload = async (objectKey, results) => {
        if (JSON.stringify(categoriesLookup) === '{}') {
            const adminCategories = await fetchCategories();
            for (let i = 0; i < adminCategories.length; i++) {
                categoriesLookup[adminCategories[i].category] = adminCategories[i].displayCategory;
            }
        }

        let csvContent = "data:text/csv;charset=utf-8,";
        let headerRow = ["id", "Name", "Category", "Locality", "City", "District", "State", "Postal Code", "Latitude", "Longitude"];
        const nearbyResultsIncluded = results.nearby && results.nearby.length > 0;
        if(nearbyResultsIncluded) {
            headerRow = headerRow.concat(["Type", "Nearby POI ID"])
        }
        csvContent += (headerRow.join(",") + "\r\n");
        for(const poi of results.pois) {
            let thisRow = [poi._id, poi.name, categoriesLookup[poi.category] ,poi.address.locality, poi.address.city, poi.address.district, poi.address.state, poi.address.postalCode, poi.l.lat, poi.l.lng];
            if (nearbyResultsIncluded) {
                thisRow = thisRow.concat(["POI", poi.nearby]);
            }
            const row = "\"" + thisRow.join("\",\"") + "\"";
            csvContent += (row + "\r\n");
        }
        if(nearbyResultsIncluded) {
            for(const poi of results.nearby) {
                const thisRow = [poi._id, poi.name, categoriesLookup[poi.category] ,poi.address.locality, poi.address.city, poi.address.district, poi.address.state, poi.address.postalCode, poi.l.lat, poi.l.lng, "Nearby POI", "-"];
                const row = "\"" + thisRow.join("\",\"") + "\"";
                csvContent += (row + "\r\n");
            }
        }

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", objectKey + ".csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        reportEvent('search', 'download', `Downloaded results with key ${objectKey}`);
    }

    fetchSavedResult = (objectKey, showNow) => {
        if(objectKey in savedSearches) {
            const results = savedSearches[objectKey];
            if (showNow) {
                this.setMarkers(results.pois);
            } else {
                this.offerDownload(objectKey, results);
            }
        } else {
            const thisComponent = this;
            this.setState({ fetchingPreviousResults: true });
            downloadResults(objectKey).then((results) => {
                savedSearches[objectKey] = results;
                if(showNow) {
                    this.setMarkers(results.pois);
                    reportEvent('search', 'download', `Clicked show results on map with key ${objectKey}`);
                } else {
                    this.offerDownload(objectKey, results);
                    reportEvent('search', 'download', `Clicked download results with key ${objectKey}`);
                }
                thisComponent.setState({fetchingPreviousResults: false});
            });
        }
    }

    initPayment = () => {
        const {user} = this.props;
        if(!user) return;
        const {orderId} = this.state;
        reportEvent('search', 'click', `Started payment for orderId ${orderId}`);
        try {
            initPayment(orderId).then((result) => {
                this.setState({
                    rzpOrderId: result.orderId
                }, () => {
                    this.purchaseSearch();
                });
            });
        } catch (error) {
            console.log(error);
        }
    }

    purchaseSearch = () => {
        const {price, orderId, rzpOrderId, resultCount} = this.state;
        const thisComponent = this;
        let options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY,
            "amount": price,
            "name": "",
            "description": `Purchasing search id ${orderId} with ${resultCount} result(s)`,
            'order_id': rzpOrderId,
            "handler": function (response) {
                console.log("razorpay response: ", response);
                thisComponent.verifyPurchase(response);
            },
            "prefill": {
                "contact": this.props.user.phone,
            },
            "theme": {
                "color": "#528ff0"
            },
            currency: 'INR'
        };

        const rzpl = new window.Razorpay(options);
        rzpl.open();
    }

    checkIfDownloadAvailable = () => {
        this.setState({waitingForDownload: true, downloadTextIndex: this.state.downloadTextIndex + 1});
        const thisComponent = this;
        downloadResults(this.state.orderId).then((results) => {
            savedSearches[this.state.orderId] = results;
            thisComponent.offerDownload(this.state.orderId, results);
            thisComponent.setState({ waitingForDownload: false, downloadTextIndex: 0 });
            window.clearInterval(downloadInterval);
            this.downloadPreviousResults();
        }).catch((err) => {
            // console.log("error", err);
        });
    }

    verifyPurchase = (rzpResponse) => {
        const {orderId} = this.state;
        const { razorpay_payment_id, razorpay_signature, razorpay_order_id } = rzpResponse;
        verifyPurchase({
            orderId,
            razorpay_order_id,
            razorpay_payment_id,
            razorpay_signature
        });
        this.toggleDownloadWaiting();
        downloadInterval = window.setInterval(() => {
            this.checkIfDownloadAvailable();
        }, 5000);
    }

    toggleDownloadWaiting = () => {
        this.setState({waitingForDownload: !this.state.waitingForDownload, downloadTextIndex: 0});
        window.clearInterval(downloadInterval);
    }

    reset = () => {
        reportEvent('search', 'click', 'Reset button clicked');
        this.clearMap();
        this.setState({
            locationQuery: "",
            category: [],
            relatedCategory: [],
            relatedRadius: relatedRadiusOptions[2],
            showAdvancedSearchTools: false,
        });
    }

    render() {
        const { fetchingPOIs, fetchingCategories, fetchingCounts, countsFetched, locationQuery, samples, categories, markers, showAdvancedSearchTools, latitude, longitude, zoom, category, phonePresent, emailPresent, displayCaptcha, mapsLoaded, boundingBox, resultCount, selectedTab, previousResults, selectedResult, fetchingPreviousResults, price, waitingForDownload, downloadTextIndex, relatedRadius, relatedCategory, nearbyMarkers } = this.state;
        const {user} = this.props;
        return (
            <React.Fragment>
                <Box className="poi-search-box">
                    <div className="poi-search-row">
                        <div className="poi-search-field-container">
                            <div className="poi-search-field">
                                <div className="poi-search-field-icon">
                                    <LocationOnIcon />
                                </div>
                                <div className="poi-search-field-control">
                                    <TextField
                                        style={{width: "100%"}}
                                        id="autocomplete-input"
                                        onChange={(e) => this.setLocationQuery(e.target.value)}
                                        placeholder={null}
                                        value={locationQuery}
                                        disabled={selectedTab !== 0}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="poi-search-field-container" >
                            <div className="poi-search-field">
                                <div className="poi-search-field-icon">
                                    <FormatListBulletedIcon />
                                </div>
                                <div className="poi-search-field-control">
                                    <Autocomplete
                                        id="category-select"
                                        value={category}
                                        multiple
                                        options={categories}
                                        getOptionLabel={(option) => option.label}
                                        style={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select category" />}
                                        onChange={(e, value) => this.setState({ category: value })}
                                        disabled={selectedTab !== 0}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="poi-search-field-container">
                            <div className="poi-search-field">
                                <div className="poi-search-field-icon">
                                    <RadioButtonCheckedIcon />
                                </div>
                                <div className="poi-search-field-control">
                                    <Autocomplete
                                        id="radius-select"
                                        options={radiusOptions}
                                        getOptionLabel={(option) => option.label}
                                        style={{ width: '100%' }}
                                        renderInput={(params) => <TextField {...params} label="Select radius" />}
                                        onChange={(e, value) => this.setState({ category: value })}
                                        disabled={selectedTab !== 0}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="poi-search-field-container" style={{flex: 1, alignItems: 'center'}}>
                            <div className="poi-search-field">
                                <Button selected={showAdvancedSearchTools} variant={showAdvancedSearchTools ? 'contained' : 'outlined'} color={showAdvancedSearchTools ? 'secondary' : 'primary'} startIcon={<TuneIcon />} onClick={this.showAdvancedTools} disabled={selectedTab !== 0}>Advanced</Button>
                            </div>
                        </div>
                        <div className="poi-search-field-container" style={{ flex: 1, alignItems: 'center' }}>
                            <div className="poi-search-field">
                                <Button variant="contained" className="intents-text-container" startIcon={<SearchIcon />} onClick={this.checkCaptcha}>Search</Button>
                            </div>
                        </div>
                        <div className="poi-search-field-container" style={{ flex: 1, alignItems: 'center' }}>
                            <div className="poi-search-field">
                                <Button color="error" variant="contained" startIcon={<ClearIcon />} onClick={this.reset}>Reset</Button>
                            </div>
                        </div>
                    </div>
                    <Collapse in={showAdvancedSearchTools}>
                        {/* <div className="poi-search-row">
                            <div className="poi-search-field-container">
                                <div className="poi-search-field" onClick={() => {if(selectedTab === 0) this.setState({ phonePresent: !phonePresent })}}>
                                    <div className="poi-search-field-icon">
                                        <Switch color="primary" checked={phonePresent} disabled={selectedTab !== 0} />
                                    </div>
                                    <div className="poi-search-field-control" style={{fontWeight: phonePresent ? 'bold' : 'normal'}}>
                                        Must have a listed phone number
                                    </div>
                                </div>
                            </div>
                            <div className="poi-search-field-container">
                                <div className="poi-search-field" onClick={() => {if(selectedTab === 0) this.setState({ emailPresent: !emailPresent })}}>
                                    <div className="poi-search-field-icon">
                                        <Switch color="primary" checked={emailPresent} disabled={selectedTab !== 0} />
                                    </div>
                                    <div className="poi-search-field-control" style={{ fontWeight: emailPresent ? 'bold' : 'normal' }}>
                                        Must have a listed email address
                                    </div>
                                </div>
                            </div>
                            <div className="poi-search-field-container" style={{flex: 3}}>

                            </div>
                        </div> */}
                        <div className="poi-search-row">
                            <div className="poi-search-field-container" style={{flex: 4}}>
                                <div className="poi-search-field">
                                    <div className="poi-search-field-icon">
                                        <FormatListBulletedIcon />
                                    </div>
                                    <div className="poi-search-field-control" style={{ marginTop: -17, flex: 22 }}>
                                        <Autocomplete
                                            id="related-category-select"
                                            value={relatedCategory}
                                            multiple
                                            options={categories}
                                            getOptionLabel={(option) => option.label}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Select related category" />}
                                            onChange={(e, value) => this.setState({ relatedCategory: value })}
                                            disabled={selectedTab !== 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="poi-search-field-container" style={{flex: 3}}>
                                <div className="poi-search-field">
                                    <div className="poi-search-field-icon">
                                        <RadioButtonCheckedIcon />
                                    </div>
                                    <div className="poi-search-field-control" style={{ marginTop: -17 }}>
                                        <Autocomplete
                                            id="radius-select"
                                            value={relatedRadius}
                                            options={relatedRadiusOptions}
                                            getOptionLabel={(option) => option.label}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => <TextField {...params} label="Select radius for related category" />}
                                            onChange={(e, value) => this.setState({ relatedRadius: value })}
                                            disabled={selectedTab !== 0}
                                        />
                                    </div>
                                    <div className="poi-search-field-icon">
                                        <Tooltip title="When selected, only those Points of Interest that are in the vicinity of another Point of Interest of this selected category will be selected.">
                                            <HelpIcon color="primary" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="poi-search-field-container" style={{ flex: 1 }}>
                                
                            </div> */}
                        </div>
                    </Collapse>
                </Box>
                <div className="results-container">
                    <div className="maps-container">
                        { mapsLoaded &&
                            <SearchMap
                                zoom={zoom}
                                center={{ lat: latitude, lng: longitude }}
                                loadingElement={<div style={{ height: '100%' }}> Loading... </div>}
                                containerElement={<div className="search-map-container" />}
                                mapElement={<div style={{ height: '100%' }}>
                                    <div style={{ position: 'absolute', top: 5, backgroundColor: 'white', boxShadow: '0px 1px 4px #ccc', marginLeft: '97px', height: '27px', width: '20px', padding: '0px 2px', cursor: 'pointer' }} >
                                        
                                    </div>
                                </div>}
                                data={markers}
                                updateMap={this.updateMap}
                                clearMap={this.clearMap}
                                bounds={boundingBox}
                                selectedTab={selectedTab}
                                nearby={nearbyMarkers}
                            />
                        }
                        { (fetchingPOIs || fetchingCategories || fetchingCounts || fetchingPreviousResults) &&
                            <div style={{ position: 'absolute', background: 'rgba(255,255,255,0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', top: '0px', left: '0px', flexDirection: 'column', minHeight: '100px' }}>
                                <div>
                                    <CircularProgress size="30" style={{ width: 30 }} />
                                </div>
                                <div style={{color: 'grey'}}>
                                    Filtering relevant points of interest...
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{width: '30%'}}>
                        <div style={{width: '100%'}}>
                            <Tabs value={selectedTab} onChange={this.changeTab} fullWidth>
                                <Tab label={<div>{selectedTab === 0 ? 'New Search' : '< Back'}</div>} {...tabProps(0)}></Tab>
                                <Tab disabled={!user} label={<div>Previous Searches {!user ? " (login to view)" : ""}</div>} {...tabProps(1)}>Previous Searches</Tab>
                            </Tabs>
                        </div>
                        <div style={{ width: '100%' }}>
                            <TabPanel value={selectedTab} index={0} >
                                {countsFetched &&
                                    <React.Fragment>
                                        { resultCount !== null && resultCount > 0 && 
                                            <React.Fragment>
                                                { user &&
                                                    <Button variant="contained" style={{marginBottom: 15}} onClick={this.initPayment}>Purchase {resultCount} result(s) for {getRupees(price/100)}</Button>
                                                }
                                                { !user &&
                                                    <div style={{ textAlign: 'center', marginBottom: 15 }}>Please <a href='#' onClick={(e) => { e.preventDefault(); this.props.toggleLoginDialog() }}>login</a> to purchase these results</div>
                                                }
                                            </React.Fragment>
                                        }
                                        <div className="poi-grid-container" >
                                            <div className="intents-text-container" style={{ width: '100%', fontSize: '1.3em', padding: '10px', fontWeight: 'bold', marginBottom: '2px' }}>
                                                Sample Results
                                                {resultCount === 0 &&
                                                    <div style={{ color: '#d32f2f', marginTop: '10px', fontSize: '0.8rem' }}>
                                                        - No results found
                                                    </div>
                                                }
                                            </div>
                                            {samples && samples.length > 0 &&
                                                <ResultGrid samples={samples} />
                                            }
                                        </div>
                                    </React.Fragment>
                                }
                                {!countsFetched &&
                                    <div style={{ overflow: 'hidden', position: 'relative' }}>
                                        <List>
                                            <ListItem>
                                                <ListItemIcon><CircleIcon fontSize="15" /></ListItemIcon>
                                                <ListItemText>
                                                    Use the search tools above to find the location of interest. You can search for location, categories, etc., and use advanced tools to filter your search.
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon><CircleIcon fontSize="15" /></ListItemIcon>
                                                <ListItemText>
                                                    Drag the map to fine tune area of interest, or draw a shape using the Drawing tool (<SquareTwoToneIcon style={{ verticalAlign: 'middle', color: '#555' }} />).
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemIcon><CircleIcon fontSize="15" /></ListItemIcon>
                                                <ListItemText>
                                                    Once you have finalized your search, click 'Next' below to fetch exact POI count, sample results and cost.
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        <div className="button_slide active" onClick={this.fetchCounts} style={{ display: 'flex', flex: 1, cursor: 'pointer' }}>
                                            <React.Fragment>
                                                <LabelImportantIcon style={{ marginRight: '10px' }} />
                                                Next
                                            </React.Fragment>
                                        </div>
                                    </div>
                                }
                            </TabPanel>
                            <TabPanel value={selectedTab} index={1}>
                                <div className="previous-results-container">
                                    {previousResults && previousResults.length > 0 && 
                                        <div style={{position: 'relative'}}>
                                            { previousResults.map((r, resultIndex) =>
                                                <Accordion expanded={selectedResult === resultIndex} onChange={() => this.togglePanel(resultIndex, r.key)} key={resultIndex}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        Purchased on {new Date(r.lastModified).toLocaleDateString()} {new Date(r.lastModified).toLocaleTimeString()}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style={{marginBottom: '15px'}}>
                                                            <Button variant="contained" startIcon={<VisibilityIcon />} onClick={() => this.fetchSavedResult(r.key, true)}>Show Results on Map</Button>
                                                            <Button variant="contained" style={{marginLeft: 10}} startIcon={<DownloadIcon />} onClick={() => this.fetchSavedResult(r.key, false)}>Download Results</Button>
                                                        </div>
                                                        { samples && samples.length > 0 &&
                                                            <ResultGrid samples={samples} />
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </div>
                                    }
                                    {!fetchingPreviousResults && (!previousResults || previousResults.length === 0) &&
                                        <div style={{textAlign: 'center'}}>No purchased searches</div>
                                    }
                                    {/* { (user && (!previousResults || previousResults.length === 0)) &&
                                        <div style={{textAlign: 'center'}}>No purchased searches</div>
                                    } */}
                                    {/* { !user &&
                                        <div style={{textAlign: 'center'}}>Please login to view purchased searches</div>
                                    } */}
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </div>
                <Modal disablePortal open={displayCaptcha} onClose={this.hideCaptcha} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{timeout: 500}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} keepMounted>
                    <Fade in={displayCaptcha} {...this.props}>
                        <Box style={{ padding: 50, backgroundColor: 'white', position: 'relative', borderRadius: 5 }}>
                            <div style={{width: '304px', fontSize: '0.8rem', marginBottom: 10}}>Please complete the CAPTCHA to help us prevent misuse. We will only show this once per session.</div>
                            <ReCAPTCHA
                                sitekey="6Lfc4HIgAAAAAOuVYjf_9NQr2Wx-IPwl48RXC6A7"
                                onChange={this.setCaptcha}
                                ref={(el) => { this.captcha = el; }}
                            />
                            <div style={{position: "absolute", top: 15, right: 15, cursor: 'pointer'}}>
                                <CloseIcon onClick={this.hideCaptcha} />
                            </div>
                        </Box>
                    </Fade>
                </Modal>
                <Modal open={waitingForDownload} onClose={this.toggleDownloadWaiting} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '50%', margin: 'auto', overflow: 'auto', height: '80%' }}>
                    <Fade in={waitingForDownload} {...this.props}>
                        <Card style={{ position: 'relative', padding: '20px' }} >
                            <CardContent style={{ padding: 0 }}>
                                <img src="/images/boxanim.gif" alt="Preparing download" width={450} />
                                <div style={{textAlign: 'center', marginTop: 15, maxWidth:'450px'}}>
                                    <TextTransition springConfig={presets.wobbly} style={{width: '100%', height: '2em'}}>
                                        <div style={{width: '100%', textAlign: 'center'}}>{downloadText[downloadTextIndex % downloadText.length]}</div>
                                    </TextTransition>
                                </div>
                            </CardContent>
                        </Card>
                    </Fade>
                </Modal>
            </React.Fragment>
        );
    }
}

export default SearchPage;