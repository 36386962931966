import './App.css';
import Home from './pages/Home';
import { ThemeProvider } from '@mui/material';
import {theme} from './util/theme';
import ReactGA from 'react-ga';
import { useEffect } from 'react';

ReactGA.initialize(process.env.REACT_APP_GOOGLEANALYTICS_TRACKING_ID || '');

function App() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
    </div>
  );
}

export default App;
