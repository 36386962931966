import React, {Component} from 'react';
import BackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';

class BackButton extends Component {
    render() {
        if(!this.props.location) return null;
        return (
            <div className="back-link">
                <Button startIcon={<BackIcon />} onClick={() => this.props.history.goBack()}>Back</Button>
            </div>
        )
    }
}

export default BackButton;