import React, { Component, forwardRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import ArrowBack from "@mui/icons-material/ArrowBack";
import LogoutIcon from '@mui/icons-material/Logout';
import PaymentIcon from '@mui/icons-material/Payment';

import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import ReCAPTCHA from "react-google-recaptcha";

import PhoneNumber from "./PhoneNumber";
import AuthService from "../util/auth.service";
import ScriptService from "../util/loadScript";
import 'react-phone-number-input/style.css';

const balanceOptions = [
    {
        value: 100,
        display: "₹100",
    },
    {
        value: 200,
        display: "₹200",
    },
    {
        value: 500,
        display: "₹500",
    },
    {
        value: 1000,
        display: "₹1000",
    },
];

class Header extends Component {
    
    state = {
        phone: '',
        phoneValid: false,
        otpScreen: false,
        otp: '',
        working: false,
        reCaptcha: "",
        addBalanceScreen: false,
        balanceToAdd: 200,
        showMenu: false,
        anchorEl: null,
        error: null,
        otpError: "",
        loginError: ""
    }

    componentDidMount() {
        const authService = AuthService.getInstance();
        authService.registerDevice();
    }

    setPhone = (phone) => {
        this.setState({
            phone: phone,
            phoneValid: phone && isValidPhoneNumber(phone)
        });
    }

    setCaptcha = (value) => {
        if (value) {
            this.setState({ reCaptcha: value });
        }
    }
    
    setOTP = (otp) => {
        this.setState({
            otp,
            otpValid: otp.length === 6
        });
    }

    nextAction = () => {
        if(this.state.working || (!this.state.otpScreen && !this.state.phoneValid && !this.state.reCaptcha) || ( this.state.otpScreen && !this.state.otpValid)) {
            return;
        }
        this.setState({
            working: true, 
            error: null
        });
        const authService = AuthService.getInstance();
        if(!this.state.otpScreen) {
            authService.getOtp(this.state.phone, this.state.reCaptcha).then((response) => {
                if (response.status === 400) {
                    this.setState({
                        otpError: response.message,
                        working: false
                    });
                    return;
                }
                this.setState({
                    otpScreen: true,
                    otp: '',
                    otpValid: false,
                    working: false,
                    otpError: ""
                });
            })
        } else {
            authService.login(this.state.otp, this.state.phone, this.state.reCaptcha).then((response) => {
                if(response.status === 400) {
                    this.setState({
                        loginError: response.message,
                        otp: '',
                        otpValid: false,
                        working: false
                    });
                    return;
                }
                this.setState({
                    otpScreen: false,
                    otp: '',
                    phone: '',
                    phoneValid: false,
                    otpValid: false,
                    reCaptcha: "",
                    working: false,
                });
                this.props.homelogin();
                this.props.toggleLoginDialog();
            });
        }
    }
    
    goToPhoneScreen = () => {
        this.setState({
            otpScreen: false,
            otp: '',
            otpValid: false
        });
    }

    logout = () => {
        const authService = AuthService.getInstance();
        authService.logout();
        this.props.homelogout();
    }

    showAddBalanceScreen = () => {
        const scriptService = ScriptService.getInstance();
        const res = scriptService.loadScript("https://checkout.razorpay.com/v1/checkout.js", () => {});
        this.setState({
            addBalanceScreen: true
        })
    }

    hideAddBalanceScreen = () => {
        this.setState({
            addBalanceScreen: false
        });
    }

    setBalanceToAdd = (event) => {
        const balanceToAdd = parseFloat(event?.target?.value);
        if (isNaN(balanceToAdd) && event?.target?.value !== "") return;
        this.setState({ balanceToAdd });
    }

    addBalance = () => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: Math.round(this.state.balanceToAdd*100),
            currency: "INR",
            name: "Intents.Mobi",
            description: "Recharge balance for POI research",
            image: "https://intents.mobi/wp-content/uploads/2021/05/Intents-Mobi-Complete-Logo.png",
            // order_id: orderId,
            handler: function(response) {
                console.log(response);
            },
            prefill: {
                name: this.props.user.preferred_username,
                // email: "",
                // contact: ""
            },
            theme: {
                color: '#2d7eb9'
            }
        }

        const rzpl = new window.RazorPay(options);
        rzpl.on("payment.failed", function(response) {
            console.log(response);
        });
        rzpl.open();
    }

    showMenu = (event) => {
        this.setState({showMenu: true, anchorEl: event.currentTarget});
    }

    hideMenu = () => {
        this.setState({showMenu: false, anchorEl: null});
    }

    render() {
        const { phone, phoneValid, otpScreen, otp, otpValid, working, reCaptcha, addBalanceScreen, balanceToAdd, showMenu, anchorEl, otpError, loginError } = this.state;
        const { user, loggingIn, dialogOpen } = this.props;
        return(
            <div style={{flexGrow: 1, marginBottom: '20px'}}>
                <AppBar position="static" style={{ backgroundColor: 'white' }}>
                    <Toolbar>
                        <Typography variant="h6" color="inherit">
                            <img src="https://intents.mobi/wp-content/uploads/2021/05/Intents-Mobi-Complete-Logo.png" height="40" style={{height: "40px", width: "auto", marginRight: '60px'}} alt="Intents Mobi" />
                        </Typography>
                        <Typography variant="h6" style={{ color: '#2d7eb9', flexGrow: 1, textAlign: 'left'}}>
                            Market iQ - The Intents Market Intelligence Data Service
                        </Typography>
                        { loggingIn &&
                            <div><CircularProgress /></div>
                        }
                        { !loggingIn && user &&
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly'}}>
                                <span style={{ color: '#2d7eb9', marginLeft: '20px' }}>Logged in as {user.preferred_username} </span>
                                <IconButton className="intents-text-container" variant="contained" style={{ marginLeft: '20px' }} onClick={this.showMenu} ><AccountCircleIcon /></IconButton>
                                <Menu anchorEl={anchorEl} open={showMenu} onClose={this.hideMenu} keepMounted>
                                    <MenuItem onClick={this.logout}><LogoutIcon /> Logout</MenuItem>
                                </Menu>
                            </div>
                        }
                        { !loggingIn && !user &&
                            <div>
                                <Button className="intents-text-container" variant="contained" startIcon={<AccountCircleIcon />} onClick={this.props.toggleLoginDialog} >
                                    Login
                                </Button>
                            </div>
                        }
                    </Toolbar>
                </AppBar>
                <Modal open={dialogOpen} onClose={this.props.toggleLoginDialog} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} keepMounted >
                    <Fade in={dialogOpen} {...this.props}>
                        <Card style={{ position: 'relative' }} >
                            <CardContent style={{padding: 0}}>
                                <div>
                                    <img src="/images/hi.png" width={450} height={250} />
                                </div>
                                <div style={{width: '450px'}}>
                                    <div style={{padding: '40px'}}>
                                        { !otpScreen &&
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                                <div style={{width: '100%'}}>
                                                    <PhoneInput
                                                        placeholder="Login with your mobile number"
                                                        value={phone}
                                                        onChange={this.setPhone}
                                                        inputComponent={PhoneNumber}
                                                        international={false}
                                                        defaultCountry="IN"
                                                    />
                                                </div>
                                                <div style={{width: '100%', marginTop: 20, textAlign: 'center'}}>
                                                    <div style={{display: 'inline-block'}}>
                                                        <ReCAPTCHA
                                                            sitekey={process.env.REACT_APP_GOOGLERECAPTCHA_KEY}
                                                            onChange={this.setCaptcha}
                                                            ref={(el) => { this.captcha = el; }}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{fontSize: '0.9rem', marginTop: 20, textAlign: 'center'}}>
                                                    Next, we will send an OTP to this number to verify it's you.
                                                </div>
                                                <div style={{ height: '20px', textAlign: 'center', color: "red" }}>{otpError}</div>
                                            </div>
                                        }
                                        <Slide direction="left" in={otpScreen}  mountOnEnter unmountOnExit>
                                            <div>
                                                <OtpInput
                                                    value={otp}
                                                    numInputs={6}
                                                    onChange={this.setOTP}
                                                    inputStyle={{
                                                        padding: '10px',
                                                        width: '1.5em',
                                                        height: '1.5em',
                                                        marginRight: '10px',
                                                        fontSize: '1.1rem',
                                                    }}
                                                    shouldAutoFocus
                                                />
                                                <div style={{ fontSize: '0.9rem', marginTop: 20, textAlign: 'center' }}>
                                                    {"You should receive a 6-digit OTP on " + phone}
                                                </div>
                                                <div style={{ height: '20px', textAlign: 'center', color: "red" }}>{loginError}</div>
                                            </div>
                                        </Slide>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                                    { otpScreen &&
                                        <div className="button_back" onClick={this.goToPhoneScreen} style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                            <ArrowBack />
                                        </div>
                                    }
                                    <div className={"button_slide " + (!working && ((!otpScreen && phoneValid && reCaptcha) || (otpScreen && otpValid)) ? 'active' : '')} onClick={this.nextAction} style={{display: 'flex', flex: 10}}>
                                        { working &&
                                            <CircularProgress size={24} style={{marginRight: '10px'}} />
                                        }
                                        { !working && otpScreen &&
                                            <React.Fragment>
                                                <LabelImportantIcon style={{ marginRight: '10px' }} />
                                                Login
                                            </React.Fragment>
                                        }
                                        {!working && !otpScreen &&
                                            <React.Fragment>
                                                <SendIcon style={{marginRight: '10px'}} />
                                                Send OTP
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Fade>
                </Modal>
                <Modal open={addBalanceScreen} onClose={this.hideAddBalanceScreen} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Fade in={addBalanceScreen} {...this.props}>
                        <Card style={{ position: 'relative' }} >
                            <CardContent style={{ padding: 0 }}>
                                <div>
                                    <img src="/images/pay.png" width={450} height={250} />
                                </div>
                                <div style={{ width: '450px' }}>
                                    <div style={{ padding: '40px' }}>
                                        <TextField type="number" value={balanceToAdd} onChange={this.setBalanceToAdd} fullWidth />
                                    </div>
                                    <div style={{ paddingLeft: '40px', paddingBottom: '10px', fontSize: '0.9rem' }}>
                                        Common recharge values:
                                    </div>
                                    <div style={{ padding: '0px 40px 40px 40px'}}>
                                        <ButtonGroup fullWidth>
                                            { balanceOptions.map((val) => (
                                                <Button variant={balanceToAdd === val.value ? "contained" : "outlined"} color="primary" key={val.display} onClick={() => this.setState({balanceToAdd: val.value})}>{val.display}</Button>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                                    <div className={"button_slide " + ((balanceToAdd > 0) ? 'active' : '')} onClick={this.addBalance} style={{ display: 'flex', flex: 10 }}>
                                        {working &&
                                            <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                        }
                                        {!working &&
                                            <React.Fragment>
                                                <LabelImportantIcon style={{ marginRight: '10px' }} />
                                                Continue
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default Header;