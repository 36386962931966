import { adminRequest } from "./fetchService"
import urlLibrary from "./urlLibrary"

export const fetchStats = (boundingBox: string, categories: string, relatedCategories: string, relatedRadius: any) => {
    return adminRequest(urlLibrary.poi.fetchPOIs(boundingBox, categories, relatedCategories, relatedRadius));
}

export const fetchCounts = (boundingBox: string, categories: string, relatedCategories: string, relatedRadius: any) => {
    return adminRequest(urlLibrary.poi.fetchCounts(boundingBox, categories, relatedCategories, relatedRadius));
}

export const fetchUserCategories = () => {
    return adminRequest(urlLibrary.categories.fetchUserCategories);
}

export const fetchCategories = async () => {
    const results = await adminRequest(urlLibrary.categories.fetchCategories);
    return results;
}

export const fetchUserList = () => {
    return adminRequest(urlLibrary.poi.userList);
}

export const initPayment = (orderId: string) => {
    return adminRequest(urlLibrary.poi.initPayment, {orderId});
}

export const downloadResults = (objectKey: string) => {
    return adminRequest(urlLibrary.poi.downloadResult(objectKey));
}

export const verifyPurchase = (data: any) => {
    return adminRequest(urlLibrary.poi.verifyPurchase, data);
}