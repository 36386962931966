export const getNumber = (num: number | null | undefined, round: boolean = false) => {
    if (num === null || typeof num === 'undefined') return '-';
    let s = num.toFixed(2).toString();
    let afterPoint = '';
    if (s.indexOf('.') > 0) {
        afterPoint = s.substring(s.indexOf('.'), s.length);
    }
    s = Math.floor(num).toString();
    let lastThree = s.substring(s.length - 3);
    let otherNum = s.substring(0, s.length - 3);
    if (otherNum !== '') {
        lastThree = ',' + lastThree;
    }
    let output = otherNum.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

    return output;
}

export const getRupees = (num: number | null | undefined) => {
    if (num === null || typeof num === 'undefined') return '-';
    return '₹' + getNumber(num);
}

export const getL = (num: number) => {
    const thousand = 1000;
    const lakh = 100000;
    const cr = 10000000;
    if (num < thousand) {
        return getNumber(num);
    }
    const divider = num > cr ? cr : num > lakh ? lakh : thousand;
    let s = Math.round(num) * 10 / divider;
    s = Math.floor(s);
    s = s / 10;
    return s.toString() + (num > cr ? 'cr' : num > lakh ? 'L' : 'k');
}

export const getLRupees = (num: number) => {
    return '₹' + getL(num);
}