export default class ScriptService {
    static instance = null;
    scripts = {};

    static createInstance() {
        var object = new ScriptService();
        return object;
    }

    static getInstance() {
        if (!ScriptService.instance) {
            ScriptService.instance = ScriptService.createInstance();
        }
        return ScriptService.instance;
    }

    loadScript = async (url, callback) => {
        if(url in this.scripts) return;
        let script = document.createElement("script");
        script.type = "text/javascript";
        this.scripts[url] = true;
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {
            script.onload = () => callback();
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    };
}