export interface RequestParams {
    method: string,
    url: string,
}

const auth: { [key: string]: RequestParams } = {
    registerDevice: {
        method: "POST",
        url: "device-register"
    },
    getOTP: {
        method: "POST",
        url: "gen-phone-otp"
    },
    verifyOTP: {
        method: "POST",
        url: "verify-phone-otp"
    },
    resendOTP: {
        method: "POST",
        url: "resend-phone-otp"
    },
    refreshTokens: {
        method: "POST",
        url: "refresh/tokens"
    }
}

const poi = {
    fetchPOIs: (boundingBox: string, categories: string, relatedCategories: string, relatedRadius: any) => ({
        method: 'GET',
        url: `poi/geo/stats?bounds=${boundingBox}` + (categories ? `&category=${categories}` : "") + (relatedCategories ? (`&sCategory=${relatedCategories}` + `&radius=` + (relatedRadius ? relatedRadius.value : "1000")) : "")
    }),
    fetchCounts: (boundingBox: string, categories: string, relatedCategories: string, relatedRadius: any) => ({
        method: 'GET',
        url: `poi/geo/counts?bounds=${boundingBox}` + (categories ? `&category=${categories}` : "") + (relatedCategories ? (`&sCategory=${relatedCategories}` + `&radius=` + (relatedRadius ? relatedRadius.value : "1000")) : "")
        // url: `counts?bounds=${boundingBox}` + (categories ? `&category=${categories}` : "")
    }),
    userList: {
        method: 'GET',
        // url: 'https://admin.intents.mobi/v2/poi/geo/list'
        url: 'poi/geo/list'
    },
    initPayment: {
        method: 'POST',
        // url: 'poi/geo/initPayment'
        url: 'poi/geo/initPayment'
    },
    downloadResult: (objectKey: string) => ({
        method: 'GET',
        url: `poi/geo/download?objectKey=${objectKey}`
    }),
    verifyPurchase: {
        method: 'POST',
        url: 'poi/geo/onpayment'
    }
}

const categories = {
    fetchUserCategories: {
        method: "GET",
        // url: "https://dev-admin.intents.mobi/v2/user-categories/fetch"
        // url: "user-categories/fetch"
        url: "categories"
    },
    fetchCategories: {
        method: "GET",
        url: "categories"
    }
}

const urlLibrary = {
    auth,
    poi,
    categories
}

export default urlLibrary;