import TextField from "@mui/material/TextField";
import { forwardRef } from "react";

const phoneRef = (props, ref) => {
    return (
        <TextField
            {...props}
            inputRef={ref}
            fullWidth
            label={props.label}
        />
    )
}

export default forwardRef(phoneRef);