import AuthService from "./auth.service";
import { RequestParams } from "./urlLibrary";


export const getToken = () => {
    const authService = AuthService.getInstance();
    const tokens = authService.getTokens();
    return tokens?.access_token || "";
}

export function authHeader(requestParams: RequestParams) {
    const devToken = process.env.REACT_APP_DEV_TOKEN;
    const headers: { [key: string]: string } = {
        Authorization: "Bearer " + ( devToken ? devToken : getToken()),
        "Content-Type": "application/json"
    }
    return headers;
}

export function uploadHeader() {
    const headers: { [key: string]: string } = {
        Authorization: "Bearer " + getToken(),
    }
    return headers;
}