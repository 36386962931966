import { Component } from "react";
import Header from "../components/Header";
import AuthService from "../util/auth.service";
import SearchPage from "./SearchPage";
import Footer from '../components/Footer';

class Home extends Component {
    
    state = {
        loggedIn: false,
        user: null,
        loggingIn: true,
        dialogOpen: false
    }

    toggleLoginDialog = () => {
        this.setState({
            dialogOpen: !this.state.dialogOpen
        })
    }

    componentDidMount() {
        const authService = AuthService.getInstance();
        authService.checkLogin().then((user) => {
            this.setState({ loggedIn: !!user, user, loggingIn: false })
        });
    }

    homeLogin = () => {
        const authService = AuthService.getInstance();
        const user = authService.getUser();
        this.setState({
            user,
            loggedIn: true
        });
    }

    homeLogout = () => {
        this.setState({
            user: null,
            loggedIn: false
        });
    }

    render() {
        const {user, loggingIn, dialogOpen} = this.state;
        return(
            <>
                <Header homelogin={this.homeLogin} user={user} loggingIn={loggingIn} homelogout={this.homeLogout} dialogOpen={dialogOpen} toggleLoginDialog={this.toggleLoginDialog} />
                <SearchPage user={user} toggleLoginDialog={this.toggleLoginDialog} />
                <Footer />
            </>
        )
    }
}

export default Home;