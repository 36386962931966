import React, {Component} from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from "@mui/material/Backdrop";
import Fade from '@mui/material/Fade';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Privacy from '../pages/Privacy';
import Terms from '../pages/Terms';

class Footer extends Component {
    state = {
        dialogOpen: false,
        dialogType: ''
    }

    toggleDialog = (type) => {
        this.setState({
            dialogOpen: !this.state.dialogOpen,
            dialogType: type
        })
    }

    render() {
        const {dialogOpen, dialogType} = this.state;
        return(
            <React.Fragment>
                <div style={{position: 'fixed', left: '0px', bottom: '0px', margin: '0px 20px',backgroundColor: 'rgba(255,255,255,0.8)'}}>
                    <span onClick={() => window.open('https://intents.mobi/privacy-policy/')} style={{cursor: 'pointer', marginRight: '15px', color: '#333'}}>Privacy</span>
                    <span onClick={() => window.open('https://intents.mobi/tos')} style={{ cursor: 'pointer', marginRight: '15px', color: '#333' }}>Terms</span>
                </div>
                <Modal open={dialogOpen} onClose={() => this.toggleDialog('')} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', width: '50%', margin: 'auto', overflow: 'auto', height: '80%' }}>
                    <Fade in={dialogOpen} {...this.props}>
                        <Card style={{ position: 'relative', padding: '20px' }} >
                            <CardContent style={{ padding: 0 }}>
                                {dialogType === 'privacy' &&
                                    <Privacy />
                                }
                                {dialogType === 'terms' &&
                                    <Terms />
                                }
                            </CardContent>
                        </Card>
                    </Fade>
                </Modal>
            </React.Fragment>
        )
    }
}

export default Footer;